<template>
  <div id="website">
    <div class="page1">
      <img src="../../assets/img/china-bg.png" alt="" />
      <div class="nav_bar">
        <div class="logo">
          <img src="../../assets/home/logo.png" alt="" />
        </div>
        <ul>
          <li><router-link to="/home#page3">关于INDIBA</router-link></li>
          <li><router-link to="/home#page4">产品方案</router-link></li>
          <li><a >INDIBA资讯</a></li>
          <li><router-link to="/found">找到INDIBA</router-link></li>
          <li><router-link to="">INDIBA中国</router-link></li>
          <li><router-link to="/hall">名人堂</router-link></li>
        </ul>
        <div class="concact_tel">
          <a href="tel://010 85951863">
            <i class="ri-phone-line" style="margin-right: 8px"></i>
            010 85951863
          </a>
        </div>
      </div>
      <div class="china-pic">
        <img src="../../assets/home/earthPic.jpg" alt="" />
      </div>
      <div class="desc">
        <span class="desc-1">INDIBA中国区介绍</span>
        <span class="desc-2">INTRODUCTION <br />TO CHINA</span>
      </div>
    </div>
    <div class="text">
      <p>
        2008年新加坡INDIBA Asia亚洲分公司成立，
        伴随人们美丽与健康意识的增长，INDIBA以其出色的效果、卓越的体验，在韩国、日本、中国香港、中国台湾地区受到了消费者与社会各界的一致褒奖。
      </p>
      <p>
        同时，越来越多的中国消费者了解到INDIBA产品科技，为了更好的服务中国市场，2016年3月，英特波（北京）科技有限公司正式注册成立。
      </p>
      <p>
        七年间，INDIBA陆续于深圳、上海、成都设立办事处，累计销售设备超过2000台、帮助1500家健康美容机构为其客户提供INDIBA设备带来的卓越服务、培训和认证INDIBA操作师5862（持续增长中）人、每年，超过12000+名中国消费者因为INDIBA的突破性的技术与专业服务恢复自然健康美丽。
      </p>
      <p>
        为了更好的保护中国消费者的切身权利，INDIBA（英特波）中国积极探索防伪以及产品溯源系统，致力保护INDIBA合作伙伴利益及消费者权益，共同抵御假冒产品造成消费者权益受损。
      </p>

      <div class="text-desc">
        <img src="../../assets/svg/attention.svg" alt="" />
        <span
          >扫描INDIBA机身二维码，进入INDIBA设备溯源管理系统，即可查看设备名称、设备型号、销售时间、及所售店铺相关信息。</span
        >
      </div>

      <p>
        未来，INDIBA（英特波）将继续以科技之力，深耕中国消费者服务，成为更多更好价值的提供者。
      </p>
    </div>
    <div class="introduce">
      <p class="title1">人员介绍</p>
      <p class="label1">PERSONNEL INTRODUCTION</p>
    </div>
    <div class="swiper-wrappersss">
      <swiper :options="swiperOptions">
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/alice.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>爱丽丝</h3>
              <h4>Alice.pang</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/caiyuanwei.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>蔡苑薇</h3>
              <h4>Vera.cai</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/chengxiaoyu.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>成小玉</h3>
              <h4>Cassie.cheng</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/duancongmei.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>段丛梅</h3>
              <h4>Summer.duan</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/fuxiaoyun.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>付晓耘</h3>
              <h4>Kristen.fu</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/hanyan.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>韩燕</h3>
              <h4>vinnie.han</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/hanyupu.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>韩瑜璞</h3>
              <h4>Linda.han</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/haofangfang.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>郝芳芳</h3>
              <h4>Teri.hao</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/heyuan.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>何元</h3>
              <h4>Yoyo.he</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/houwei.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>侯薇</h3>
              <h4>Vivian.hou</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/huqingsong.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>胡庆松</h3>
              <h4>hugh.hu</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/huxiaoying.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>胡晓英</h3>
              <h4>Yuki.hu</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/jiangheen.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>姜贺恩</h3>
              <h4>Even.jiang</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/jinchunhua.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>金春花</h3>
              <h4>Flora.kim</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/jiuyue.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>酒月</h3>
              <h4>Oifa.li</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/lichen.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>李琛</h3>
              <h4>wyman.li</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/lining.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>李宁</h3>
              <h4>Jessica.li</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/liwenting.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>李雯婷</h3>
              <h4>Paris.li</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/liyang.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>李洋</h3>
              <h4>Sunny.li</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/liyiran.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>李依燃</h3>
              <h4>Irene.li</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/mengjunyang.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>孟君阳</h3>
              <h4>ivanka.meng</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/panboyu.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>潘柏予</h3>
              <h4>By.pan</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/shenwei.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>沈卫</h3>
              <h4>Jerry.shen</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/sunbingbing.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>孙冰冰</h3>
              <h4>Emily.sun</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/wuyibing.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>吴轶冰</h3>
              <h4>Bingbing.wu</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <!-- <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/xiangnina.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>向妮娜</h3>
              <h4>Nina.xiang</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide> -->
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/xujing.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>徐静</h3>
              <h4>Amy.xu</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/yaoyuxin.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>姚雨欣</h3>
              <h4>Cynthia.yao</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/yejingjing.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>叶菁菁</h3>
              <h4>Sandy.ye</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/zhanglifang.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>张立芳</h3>
              <h4>Alisa.zhang</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/zhangwenji.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>张文姬</h3>
              <h4>maggie.zhang</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/zhangxue.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>张雪</h3>
              <h4>Snow.zhang</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img
                src="../../assets/home/chinaperson/zhangzhengxue.jpg"
                alt=""
              />
            </div>
            <div class="items-des">
              <h3>张正雪</h3>
              <h4>Bill.zhang</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="items-wrapper">
            <div class="items-img-wrapper">
              <img src="../../assets/home/chinaperson/zhuoliying.jpg" alt="" />
            </div>
            <div class="items-des">
              <h3>卓岦颖</h3>
              <h4>Livia.zhuo</h4>
              <h4>中国区职员</h4>
              <h4>英特波科技(深圳)有限公司</h4>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import "vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  name: "app",
  components: {
    swiper,
    swiperSlide,
  },
  computed: {},
  data() {
    return {
      swiperOptions: {
        loop: false,
        slidesPerView: 5,
        spaceBetween: 100,
        // autoplay: {
        //   delay: 3000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        on: {
          slideChange: () => {
            // this.activeIndex = this.swiper.activeIndex + 1;
          },
        },
        pagination: {
          el: ".swiper-pagination",
          type: "custom",
        },
      },
    };
  },
  created() {
    document.title = "INDIBA中国";
  },
  methods: {},
};
</script>

<style lang="less" less="scoped">
#website {
  min-width: 1280px;
  @font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  user-select: none;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  //   align-items: center;
}

.page1 {
  img {
  }
  position: relative;
}

.ri-phone-line:before {
  content: "\efec";
}

.nav_bar {
  width: 100%;
  position: absolute;
  z-index: 2022;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  line-height: 48px;
  padding: 55px 80px;
  li {
    display: inline-block;
  }
  .logo {
    cursor: pointer;
  }

  a {
    line-height: 40px;
    color: #fff;
    padding: 20px;
    &:hover {
      // background-color: #060;
    }
  }

  .concact_tel {
    a {
      display: flex;
      align-items: center;
    }
  }
}
.china-pic {
  width: 35%;
  position: absolute;
  top: 80px;
  right: 0;
//   border: 1px solid #fff;
//   background-color: ;
  img{
    box-shadow:0 0 100px 10px #36498A;
  }
}
.desc {
  position: absolute;
  bottom: 15%;
  left: 300px;
  .desc-1 {
    font-size: 28px;
    font-weight: bold;
    line-height: 24px;
    color: #ffffff;
    display: block;
  }
  .desc-2 {
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.45);
    margin-top: 10px;
  }
}
.text {
  max-width: 1000px;
  margin: 64px auto;
  margin-bottom: 32px;
  p {
    padding-bottom: 16px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 400;
  }
  .text-desc {
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 16px;
    img {
      width: 12px;
      height: 12px;
    }
    height: 48px;
    line-height: 48px;
    background: rgba(54, 90, 189, 0.1);
    width: 100%;
    color: rgba(54, 90, 189, 0.9);
    font-size: 16px;
    font-weight: 400;
    span {
      margin-left: 6px;
    }
  }
}
.introduce {
  width: 1000px;
  margin: 0 auto;
  .title1 {
    font-size: 24px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 2px;
    font-weight: bold;
  }
  .label1 {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    color: rgba(0, 0, 0, 0.65);
  }
}
.swiper-wrappersss {
  width: 1000px;

  margin: 40px auto;
  swiper-slide {
    width: 200px !important;
  }
  //   background-color: aquamarine;
  .items-wrapper {
    background-color: #e9e7ea;
    margin-top: 70px;
    width: 200px;
    height: 300px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    position: relative;
    .items-img-wrapper {
      width: 130px;
      height: 150px;
      background-color: #e9e7ea;
      //   border: 1px solid #E9E7EA;
      //   box-shadow: 2px 2px 0px 0px #E9E7EA;
      position: absolute;
      left: 50%;
      top: -70px;
      z-index: 100;
      border-radius: 50%;
      overflow: hidden;
      transform: translateX(-50%);
      img {
        width: 100%!important;
        height: auto;
      }
      border: 0.5px solid rgba(0, 0, 0, 0.1);
    }
    .items-des {
      text-align: center;
      height: 100px;
      position: absolute;
      bottom: 100px;
      left: 0;
      right: 0;
      h3 {
        color: rgba(0, 0, 0, 0.85);
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
      }
      h4:nth-child(3) {
        color: rgba(0, 0, 0, 0.85);
        font-weight: bold;
      }
      h4 {
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }
    }
  }
}
</style>
